import React from 'react'
import {withRouter} from 'react-router-dom'

import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';

import {AuthContext} from '../../main/ProvedorAutenticacao'
import Navbar from '../../componentes/Navbar'

import UsuarioService from '../../app/service/UsuarioService'
import {strings} from "../../utils/strings";
import EmpresaGraficoService from "../../app/service/EmpresaGraficoService";
import {AdicionarUsuarioProcessoAutoDialog} from "../../componentes/AdicionarUsuarioProcessoAutoDialog";
import {AdicionarCertificadoProcessoAutoDialog} from "../../componentes/AdicionarCertificadoProcessoAutoDialog";

class AlterarSenha extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();
    this.empresaGraficoService = new EmpresaGraficoService()

    this.state = {
      senhaUsuario: '',
      novaSenha: '',
      confirmacaoSenha: '',
      processoAutomatico: false,
      certificadoProcessoAutomatico: false,
      alertDialog: false,
      adicionarOuRemoverUsertoProcessDialog: false,
      usuarioConsulta: null,


    }

  }

  alterarSenha = () => {

    if(this.state.senhaUsuario === '') {
      this.toast.show({severity:'error', summary: 'Alterar senha', detail:'Informe a senha de seu usuário', life: 4000});
      return;
    }

    if(this.state.novaSenha === '') {
      this.toast.show({severity:'error', summary: 'Alterar senha', detail:'Informe a nova senha', life: 4000});
      return;
    }

    if(this.state.confirmacaoSenha === '') {
      this.toast.show({severity:'error', summary: 'Alterar senha', detail:'Informe a confirmação de senha', life: 4000});
      return;
    }

    const usuarioLogado = this.context.usuarioAutenticado;

    const usuarioSenha = {
      codigo: usuarioLogado.codigo,
      processoAutomatico: this.state.processoAutomatico // 1 para true 0 para false
    };
    const { senhaUsuario, novaSenha, confirmacaoSenha} = this.state
    const alterSenha = {
      senha: senhaUsuario,
      novaSenha: novaSenha,
      confirmacaoSenha: confirmacaoSenha,
      usuario: usuarioSenha
    }

    // this.usuarioService.alterarSenha(this.state.senhaUsuario, this.state.novaSenha.toString(),this.state.confirmacaoSenha.toString(),usuarioSenha)
    this.usuarioService.alterarSenha(alterSenha)
        .then(() => {
          this.limparCampos();
          this.toast.show({severity:'success', summary: 'Alterar senha', detail:'Senha alterada com sucesso', life: 4000});
        }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Alterar senha', detail:`${this.state.detail}`, life: 4000});
    })
  };


  limparCampos = () => {
    this.setState({senhaUsuario: ''});
    this.setState({novaSenha: ''});
    this.setState({confirmacaoSenha: ''});
  }

  onProcessoChecked = (e)=>{
      this.setState({processoAutomatico : e.checked,
        alertDialog: e.checked,
      })
  }

  onDialogClose = ()=>{
    this.setState({
      alertDialog: false,
      adicionarOuRemoverUsertoProcessDialog: false
    })
  }
    onDialogCertificadoClose = ()=>{
        this.setState({
            alertDialog: false,
            adicionarOuRemoverCertificadotoProcessDialog: false
        })
    }

  addedOrRemoverUserProcessoAuto = ()=>{
      this.setState({
        adicionarOuRemoverUsertoProcessDialog: true
      })
  }
  addedOrRemoverCertificadoProcessoAuto = ()=>{
      this.setState({
        adicionarOuRemoverCertificadotoProcessDialog: true
      })
  }

  getUser= async ()=>{
    const usuarioLogado = this.context.usuarioAutenticado;

    await this.usuarioService.consultarUsuario(usuarioLogado.codigo)
        .then(response => {
          let user = response.data
          if(user){
            this.setState(
                {usuarioConsulta: user,
                processoAutomatico: user.processoAutomatico
                })
          }
        }).catch(error=>{
          let detail = error.response.data?.detail || "Erro ao buscar dados do usuário"
          this.toast.show({severity:'error', summary: 'Alterar senha', detail:`${detail}`, life: 4000});
        })
  }

  async componentDidMount() {
    await this.getUser()
  }


  render() {
    return(
      <>
        <Navbar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
             <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header row-etrium gap-1 align-items-center">
                      <h3 className="card-title">
                        <i className="pi pi-cog"></i>
                        <span> Preferências</span>
                      </h3>
                      <div className="row-etrium gap-0 align-items-center"
                           style={{textAlign: 'right'}}>
                        <Button icon="fas fa-user-check"
                                id="btn-cap-id-127v8sfd"
                                className={this.state.processoAutomatico ? 'p-button-rounded p-button-primary' : 'p-button-rounded p-button-secondary'}
                                onClick={this.addedOrRemoverUserProcessoAuto}
                                tooltip={this.state.processoAutomatico ? 'Clique aqui para retirar usuario do cadastro automatico de processo' :
                                    "Clique aqui para adicionar o usuário ao cadastro automático de processo"}
                                tooltipOptions={{
                                  className: 'blue-tooltip',
                                  position: 'top'
                                }}
                                style={ { width: '30px', height: '27px', fontSize:'12px'}}/>
                        <Button icon={"fas fa-file-import"}
                                id="btn-cap-id-127v8sfdd"
                                className={this.state.certificadoProcessoAutomatico ? 'p-button-rounded p-button-primary' : 'p-button-rounded p-button-secondary'}
                                onClick={this.addedOrRemoverCertificadoProcessoAuto}
                                tooltip={this.state.certificadoProcessoAutomatico ? 'Clique aqui para retirar o certificado do cadastro automático de processo.' :
                                    "Clique aqui para adicionar o certificado ao cadastro automático de processo."}
                                tooltipOptions={{
                                  className: 'blue-tooltip',
                                  position: 'top'
                                }}
                                style={ { width: '30px', height: '27px', fontSize:'12px'}}/>
                        {/*<label className="ml-2">{"this.getSistemName()"} </label>*/}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Senha atual<span className="obrigatorioAsterisco"> *</span></label>
                            <Password id="senhaUsuario"
                                      size="60"
                                      type="text"
                                      autoComplete="nope"
                                      toggleMask="false"
                                      value={this.state.senhaUsuario}
                                      onChange={e => this.setState({senhaUsuario: e.target.value})}
                                      className="p-inputtext-sm p-d-block p-mb-1"
                                      />

                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Nova senha<span className="obrigatorioAsterisco"> *</span></label>
                            <Password id="novaSenha"
                                      size="60"
                                      autoComplete="nope"
                                      toggleMask
                                      value={this.state.novaSenha}
                                      onChange={e => this.setState({novaSenha: e.target.value})}
                                      className="p-inputtext-sm p-d-block p-mb-1"
                                      />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Confirmação nova senha<span className="obrigatorioAsterisco"> *</span></label>
                            <Password id="confirmacao"
                                      size="60"
                                      autoComplete="nope"
                                      value={this.state.confirmacaoSenha}
                                      toggleMask
                                      onChange={e => this.setState({confirmacaoSenha: e.target.value})}
                                      className="p-inputtext-sm p-d-block p-mb-1"
                                     />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-sm-7">
                            <Button label="Alterar"
                                    className={strings.buttonPrimaryStyle}
                                    onClick={this.alterarSenha}/>

                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {this.state.adicionarOuRemoverUsertoProcessDialog && <AdicionarUsuarioProcessoAutoDialog
            usuario={this.state.usuarioConsulta}
            open={this.state.adicionarOuRemoverUsertoProcessDialog}
            onClose={this.onDialogClose}
            reload={this.getUser}
            toast={this.toast}
        />}
          {this.state.adicionarOuRemoverCertificadotoProcessDialog && <AdicionarCertificadoProcessoAutoDialog
              usuario={this.state.usuarioConsulta}
              open={this.state.adicionarOuRemoverCertificadotoProcessDialog}
              onClose={this.onDialogCertificadoClose}
              toast={this.toast}
          />}
      </>
    )
  }
}

AlterarSenha.contextType = AuthContext;
export default withRouter (AlterarSenha)
